import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC } from 'react';
import { ArrayNumberFilter } from 'src/filters/types';
import UsedFilterWrapper from '../used-filter-wrapper';

import styles from '../filters.module.scss';

type Props = {
  filter: ArrayNumberFilter<number>;
  label: string;
};

const DepositUsedFilter: FC<Props> = ({ filter, label }) => {
  if (filter.value.length === 0) return null;

  const { updateFilter } = useOffersContext();
  const remove = (type: number) => {
    updateFilter({
      type: 'deposit',
      value: filter.value.filter((item) => item !== type)
    });
  };

  return (
    <>
      {filter.value.map((item) => (
        <UsedFilterWrapper key={item} onClear={() => remove(item)}>
          <span className={styles.usedFilters__label}>{`${label}:`}</span>
          <span className={styles.usedFilters__value}>{`${item} EUR`}</span>
        </UsedFilterWrapper>
      ))}
    </>
  );
};

export default DepositUsedFilter;
