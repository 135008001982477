import CarIcon from 'components/common/car-icon';
import classNames from 'classnames';
import PartnerLogo from 'components/common/partner-logo';
import Button from 'components/forms/button';
import React, { FC, memo, useState } from 'react';
import useTranslation from 'providers/translations/use-translations';
import { ucFirst } from 'src/utils/string';
import { CarOffer } from 'src/cars/types';
import Price from 'components/common/price';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import useCarsApi from 'providers/cars-api/use-cars-api';
import JsonPreview from 'modules/dev-tools/json-preview';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import { goToBookingPage } from 'src/url-manager';
import { FadeIn } from 'components/common/animations';
import useNonInitialEffect from 'hooks/use-non-initial-effect';
import { CalculatedPricesType } from 'src/booking-engine/types';
import OverlayWrapper from 'components/common/overlay-wrapper';
import { wsInfo } from 'src/logger';
import useOffersContext from 'providers/offers/use-offers-context';
import OfferDetails from './offer-details';
import styles from './offer-widget.module.scss';
import Equip from './equip';

type Props = {
  offer: CarOffer;
  previewOnly?: boolean;
  className?: string;
  withDetails?: boolean;
  overridePrices?: CalculatedPricesType;
};
const SingleWidgetOffer: FC<Props> = ({
  offer,
  previewOnly,
  className,
  withDetails,
  overridePrices
}) => {
  const { inhouse, dataPreview } = useDevToolsContext();
  const { isMobile } = useScreenDetect();
  const { searchId } = useOffersContext();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const { subpage } = useCarsApi();
  const [noImage, setNoImage] = useState(false);

  useNonInitialEffect(() => {
    if (!expanded) return;
    wsInfo('userInteraction', 'Expand car details', {
      offerId: offer.offerId
    });
  }, [expanded]);

  if (!!searchId && searchId !== offer.sessionId) return null;

  return (
    <>
      <div
        className={classNames('offer-widget', styles.singleOfferWidget, className, {
          [styles['isMobile']]: isMobile,
          [styles['singleOffer--previewOnly']]: previewOnly
        })}
      >
        <div className={classNames(styles.offerHeading)}>
          <span className={styles.carType}>{ucFirst(t(offer.carclass))}</span>
        </div>
        <Equip offer={offer} className={styles.carIcon} noLabels limitTo="offersWidget" />
        <div className={classNames(styles.offerPicture)}>
          {noImage ? (
            <>
              <img className={styles.noCarImage} alt={offer.name} src="/img/no-car-image.png" />
              <span>{t('no_car_image')}</span>
            </>
          ) : (
            <img
              alt={offer.name}
              src={offer.image.replace('75x75', '140x100')}
              onError={() => setNoImage(true)}
            />
          )}
        </div>
        <div className={classNames(styles.underlineSplitter1, styles.underlineSplitter)} />

        <div
          className={classNames(styles.offerPartner, {
            [styles['offerPartner--rightBottom']]: previewOnly
          })}
        >
          <PartnerLogo name={offer.partner} label="partner" textOnly />
        </div>

        {!previewOnly && (
          <>
            <div className={classNames(styles.underlineSplitter2, styles.underlineSplitter)} />
          </>
        )}
        {!isMobile && withDetails && (
          <div className={styles.offerMore}>
            <Button
              className={styles.moreBtn}
              variant="tertiary"
              size="medium"
              onClick={() => setExpanded(!expanded)}
            >
              <div className={styles.moreBtn__content}>
                <span className={styles.moreBtn__title}>
                  <span className={styles.moreBtn__label}>{t('mobile_extended_info')}</span>
                  <CarIcon
                    className={styles.moreBtn__info}
                    icon={expanded ? 'chevron-up' : 'chevron-down'}
                    size="13px"
                  />
                </span>
              </div>
            </Button>
          </div>
        )}

        {withDetails && (
          <div className={styles.offerMore}>
            <Button
              className={styles.moreBtn}
              variant="tertiary"
              size="medium"
              onClick={() => setExpanded(true)}
            >
              <div className={styles.moreBtn__content}>
                <span className={styles.moreBtn__title}>
                  <span className={styles.moreBtn__label}>{t('mobile_extended_info')}</span>
                  <CarIcon
                    className={styles.moreBtn__info}
                    icon={expanded ? 'chevron-up' : 'chevron-down'}
                    size="13px"
                  />
                </span>
              </div>
            </Button>
          </div>
        )}

        {!previewOnly && (
          <>
            <div className={styles.offerButton}>
              <Button
                className={classNames(styles.bookBtn, 'offer_bookBtn')}
                variant="primary"
                size="large"
                onClick={() => {
                  goToBookingPage(offer.offerId, inhouse, true);
                  wsInfo('userInteraction', 'Offer button clicked', {
                    offerId: offer.offerId
                  });
                }}
              >
                <CarIcon icon="chevron-right-thin" size="30px" />
              </Button>
            </div>
          </>
        )}

        <div className={styles.offerPrices}>
          <div className={styles.offerPrices__pack}>
            {(!offer.priceExchanged || subpage !== 'offerlist') && (
              <Price
                className={styles.offerPrice}
                price={(overridePrices && overridePrices.total) || offer.price}
                currency={offer.currency}
              />
            )}
            {offer.priceExchanged > 0 && (
              <span data-ftooltip-idor="tooltip_price_info">
                <Price
                  className={classNames(styles.offerPrice, styles.offerPrice__exchanged)}
                  price={
                    (overridePrices && overridePrices.totalInAgentCurrency) || offer.priceExchanged
                  }
                  currency={offer.currencyExchanged}
                  prefix={t('about_abbr', '~')}
                />
              </span>
            )}
            {!offer.priceExchanged && (
              <span className={styles.offerPricePerDay}>
                <Price
                  price={(overridePrices && overridePrices.totalPerDay) || offer.pricePerDay}
                  currency={offer.currency}
                />
                <span>
                  &nbsp;
                  {t('price_day')}
                </span>
              </span>
            )}
            {offer.priceExchanged > 0 && (
              <span className={styles.offerPricePerDay}>
                <Price
                  price={
                    (overridePrices && overridePrices.totalPerDayInAgentCurrency) ||
                    offer.pricePerDayExchanged
                  }
                  currency={offer.currencyExchanged}
                  prefix={t('about_abbr', '~')}
                />
                <span>
                  &nbsp;
                  {t('price_day')}
                </span>
              </span>
            )}
          </div>
        </div>

        {withDetails && (
          <OverlayWrapper
            title={t('mobile_extended_info')}
            open={expanded}
            closeBtn
            inUse
            className={styles.detailsOverlay}
            onClose={() => setExpanded(false)}
          >
            <FadeIn open={expanded}>
              <OfferDetails offer={offer} previewOnly={previewOnly} />
            </FadeIn>
          </OverlayWrapper>
        )}
      </div>
      {dataPreview && (
        <JsonPreview className={styles.offerJson} data={offer} title="Show offer json" />
      )}
    </>
  );
};
SingleWidgetOffer.defaultProps = {
  previewOnly: false,
  className: undefined,
  withDetails: true,
  overridePrices: {}
};

export default memo(SingleWidgetOffer);
