import React, { FC, useEffect, useState } from 'react';
import { clone } from 'lodash';
import useOffersContext from 'providers/offers/use-offers-context';
import CheckboxButton from 'components/forms/checkbox-button';
import CarIcon from 'components/common/car-icon';
import { sameArrays } from 'src/utils/array';
import { CarScore } from 'types/car-score.type';
import { ArrayFilter } from 'src/filters/types';
import useTranslation from 'providers/translations/use-translations';
import useCarsApi from 'providers/cars-api/use-cars-api';
import Price from 'components/common/price';
import classNames from 'classnames';
import FilterBox from '../filter-box';

import styles from '../filters.module.scss';

const SCORE_LIST: Array<CarScore> = ['basic', 'economy', 'premium'];

type Props = {
  label?: string;
  disabled?: boolean;
};

const CarScoreFilterBox: FC<Props> = ({ label = undefined, disabled = false }) => {
  const { filters, updateFilter, pricesStartsFrom } = useOffersContext();
  const { t } = useTranslation();
  const { general } = useCarsApi();
  const [selected, setSelected] = useState<Array<CarScore>>([]);

  const selectionChange = (carScore: CarScore, checked: boolean) => {
    if (checked) {
      selected.push(carScore);
    } else {
      selected.splice(selected.indexOf(carScore), 1);
    }
    setSelected(clone(selected));

    updateFilter({
      type: 'carScore',
      value: clone(selected)
    });
  };

  const getUnderlabel = (type) => {
    switch (type) {
      case 'basic':
        return `${t('insurance_cdw_short')} ${t('insurance_with_excess')}`;
      case 'economy':
        return `${t('0nc_insurances')} ${t('insurance_without_excess')} ${t('pickup_or')} ${t(
          '0nc_excess_refund'
        )}`;
      case 'premium':
        return `${t('0nc_insurances')} ${t('insurance_without_excess')} ${t('pickup_or')} ${t(
          '0nc_excess_refund'
        )}. ${t('0nc_insurances')} ${t('insurance_short_glass')} ${t('insurance_tyres')} ${t(
          'insurance_short_roof'
        )}`;
      default:
        return null;
    }
  };

  useEffect(() => {
    const filter = filters.find((item) => item.type === 'carScore') as ArrayFilter<CarScore>;
    if (!filter && selected.length !== 0) {
      setSelected([]);
    }

    if (filter && !sameArrays(selected, filter.value)) {
      setSelected(filter.value);
    }
  }, [filters, selected]);
  return (
    <FilterBox title={label}>
      {SCORE_LIST.map((type) => (
        <CheckboxButton
          key={type}
          disabled={
            disabled || !pricesStartsFrom['carScore'] || !pricesStartsFrom['carScore'][type]
          }
          labelClassName={classNames(styles.scoreFilter, styles.scoreFilter__withUnderlabel)}
          value={selected.indexOf(type) !== -1}
          onChange={(checked) => selectionChange(type, checked)}
        >
          <span className={styles.scoreFilter__label_container}>
            <span className={styles.scoreFilter__label}>{t(`${type}_offer`)}</span>
            {selected.indexOf(type) < 0 &&
              !disabled &&
              pricesStartsFrom['carScore'] &&
              pricesStartsFrom['carScore'][type] &&
              pricesStartsFrom['carScore'][type].price && (
                <span className={styles.price}>
                  {t('price_from')}
                  <Price
                    round
                    className={styles.offerPrice}
                    price={pricesStartsFrom['carScore'][type].price}
                    currency={general.currency}
                  />
                </span>
              )}
          </span>
          <span
            className={styles.scoreFilter__underlabel}
            dangerouslySetInnerHTML={{
              __html: getUnderlabel(type)
            }}
          />
        </CheckboxButton>
      ))}
    </FilterBox>
  );
};

export default CarScoreFilterBox;
