import React, { useState, useRef, CSSProperties, useEffect, memo } from 'react';
import classNames from 'classnames';
import camelize from 'camelize';
import { Scrollbars } from 'react-custom-scrollbars-2';
import useOnclickOutside from 'react-cool-onclickoutside';
import OverlayWrapper from 'components/common/overlay-wrapper';
import Button from 'components/forms/button';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';

import useNonInitialEffect from 'hooks/use-non-initial-effect';
import useHasMounted from 'hooks/use-has-mounted';
import { prepareToken } from 'src/utils/string';
import Spinner from 'components/common/spinner';
import InputLabel from 'components/forms/input-label';
import CarIcon from 'components/common/car-icon';
import { SlideUp } from 'components/common/animations';
import { useRouter } from 'next/router';
import { wsInfo, wsWarning } from 'src/logger';
import axiosInstance from 'src/axios-instance';
import axios from 'axios';
import useTranslation from 'providers/translations/use-translations';
import FrontendError from 'src/frontend-error.class';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import { proxyUrl } from 'src/utils';
import SuggesterRow from './suggester-row';
import { Location, SuggesterItem } from './types';

import styles from './suggester.module.scss';
import formStyles from '../forms/forms.module.scss';

interface SuggesterProps {
  id?: string;
  label?: string;
  value?: Location;
  onChange?(location: Location): void;
  classNames?: Array<string>;
  style?: CSSProperties;
  placeholder?: string;
  icon?: string;
  insideLabel: boolean;
  error?: string;
}

let cancelToken;

const Suggester: React.FC<SuggesterProps> = ({
  insideLabel,
  onChange = () => null,
  style = {},
  icon = 'a',
  id = undefined,
  label = undefined,
  value = undefined,
  placeholder = undefined,
  error = '',
  classNames: providedClassNames = []
}) => {
  const { isMobile } = useScreenDetect();
  const { customProxy } = useDevToolsContext();
  const { t } = useTranslation();
  const inputEl = useRef(null);
  const scrollbarsRef = useRef(null);
  const resultsRef = useRef(null);
  const [idx] = useState(typeof id !== 'undefined' ? id : `suggester_${prepareToken()}`);
  const [loading, setLoading] = useState(false);
  const [index, setIndex] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [selected, setSelected] = useState<Location>(value);
  const [items, setItems] = useState<Array<SuggesterItem>>([]);
  const [hover, setHover] = useState(false);
  const [focused, setFocused] = useState(false);
  const hasMounted = useHasMounted();
  const [mobileOpen, setMobileOpen] = useState(false);
  const listItems: Array<SuggesterItem> = items;
  const { locale } = useRouter();

  /* ------------------------------------------------------------------------------ INTERFACE --- */

  // Hide results
  const hideResults = () => {
    if (expanded) setExpanded(false);
    setItems([]);
    setLoading(false);
  };

  // Expand results
  const showResults = () => {
    setExpanded(true);
    setIndex(0);
    if (scrollbarsRef.current !== null) {
      scrollbarsRef.current.scrollTop(0);
    }
  };

  // Scroll result lists to n element
  const scrollToItem = (n) => {
    if (n < 0 || n >= listItems.length) return;

    const item = resultsRef.current.childNodes[n];
    if (!item) return;
    const itemOffset = item.offsetTop;
    const itemHeight = item.clientHeight;
    const topEdge = scrollbarsRef.current.getScrollTop();
    const listHeight = scrollbarsRef.current.getClientHeight();
    const bottomEdge = topEdge + listHeight;

    if (itemOffset < topEdge) {
      scrollbarsRef.current.scrollTop(itemOffset);
    } else if (itemOffset + itemHeight > bottomEdge) {
      const top = itemOffset + itemHeight - listHeight;
      scrollbarsRef.current.scrollTop(top > 0 ? top : 0);
    }
  };

  // Highlight next or previous item
  const jumpHighlight = (direction: 'next' | 'prev') => {
    let i = 0;
    if (direction === 'next') {
      i = index + 1 >= listItems.length ? 0 : index + 1;
    } else {
      i = index <= 0 ? listItems.length - 1 : index - 1;
    }
    setIndex(i);
    scrollToItem(i);
  };

  const logLocationPick = (item: SuggesterItem, i: number) => {
    if (i === 0) return;

    wsInfo(
      'alternativeSuggestionSelected',
      `Select "${item.location}" from ${i + 1} row, search phrase: "${inputEl.current.value}"`,
      {
        searchPhrase: inputEl.current.value,
        location: item.location,
        locationKey: item.locationKey,
        level: item.level,
        positionOnList: i + 1,
        suggestions: listItems.map((el, j) => ({
          i: j,
          name: el.location,
          level: el.level
        }))
      }
    );
  };

  // Select location and make request for details
  const selectLocation = (item: SuggesterItem, i: number) => {
    logLocationPick(item, i);
    if (!['PCLI', 'PCLIX', 'ADM1'].includes(item.locationType)) {
      inputEl.current.value = item.location;
      setSelected({
        locationKey: item.locationKey,
        name: item.location,
        center: {
          latitude: item.locationLat,
          longitude: item.locationLon
        }
      });
      hideResults();
      setHover(false);
      setMobileOpen(false);
    }
  };

  /* ------------------------------------------------------------------------- SEARCH PROCESS --- */

  // Try to search
  const search = () => {
    setLoading(true);
    if (typeof cancelToken !== typeof undefined) {
      cancelToken.cancel();
    }
    cancelToken = axios.CancelToken.source();

    const requestParams = {
      locale,
      phrase: inputEl.current.value
    };

    axiosInstance
      .post(`${proxyUrl(customProxy)}suggester`, requestParams, {
        cancelToken: cancelToken.token
      })
      .then(
        (res) => {
          setLoading(false);
          const locations = Array.isArray(res.data) ? res.data.map((item) => camelize(item)) : [];
          setItems(locations);

          if (locations.length > 0) {
            showResults();
          } else {
            hideResults();
            wsWarning('emptySuggesterResponse', `No suggestions for: ${inputEl.current.value}`, {
              params: requestParams
            });
          }
        },
        (e: FrontendError) => {
          if (!e.details?.canceled) {
            wsWarning('emptySuggesterResponse', `Suggester error for: ${inputEl.current.value}`, {
              params: requestParams
            });
          } else {
            // console.log('is canceled');
          }
          setLoading(false);
        }
      );
  };

  /* -------------------------------------------------------------------------- HANDLE EVENTS --- */

  // Close and reset state on click outside
  const containerEl = useOnclickOutside(() => {
    if (!expanded && !focused) return;
    hideResults();
    if (!inputEl.current) return;
    inputEl.current.value = selected ? selected.name : '';
  });

  // Suggester input keyboard events
  const handleInputKeyDown = (e) => {
    switch (e.key) {
      case 'ArrowDown':
        e.preventDefault();
        jumpHighlight('next');
        break;

      case 'ArrowUp':
        e.preventDefault();
        jumpHighlight('prev');
        break;

      case 'Enter':
        e.preventDefault();
        e.stopPropagation();
        if (listItems[index]) selectLocation(listItems[index], index);
        break;

      default:
    }
  };

  // Handle input click
  const handleInputClick = () => {
    const name = inputEl.current.value;
    if (name.length >= 3) {
      search();
    } else {
      hideResults();
    }
  };

  // Trigger search on typing
  const handleInputKeyPress = (e) => {
    if (['Enter', 'ArrowDown', 'ArrowUp'].indexOf(e.key) === -1) {
      if (inputEl.current.value.length >= 3) {
        search();
      } else {
        hideResults();
      }
    }
  };

  const handleInputFocus = () => {
    setFocused(true);
  };

  const handleInputBlur = () => {
    setFocused(false);
  };

  const handleMobileClick = () => {
    setMobileOpen(true);
    setFocused(true);
  };

  const handleMobileClose = () => {
    setMobileOpen(false);
    setFocused(false);
    setHover(false);
  };

  useEffect(() => {
    if (!inputEl?.current) return;
    if (focused === true) inputEl.current.focus();
  }, [focused]);

  // Emit change
  useNonInitialEffect(() => {
    onChange(selected);
  }, [selected]);

  useNonInitialEffect(() => {
    setSelected(value);
    if (inputEl?.current) {
      inputEl.current.value = value?.name ?? '';
    }
  }, [value]);

  /* --------------------------------------------------------------------------------- RENDER --- */

  return (
    <div
      ref={containerEl}
      className={classNames(styles.outerContainer, providedClassNames, {
        [styles['outerContainer--focused']]: (hover || focused) && !isMobile,
        [styles['outerContainer--error']]: error
      })}
      style={{
        ...style,
        zIndex: hover || focused ? 10 : undefined,
        position: mobileOpen ? 'absolute' : 'relative'
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {isMobile && (
        <div
          className={classNames(
            'suggester_innerContainer',
            styles.innerContainer,
            formStyles.iconField__container,
            {
              [formStyles['iconField--focused']]: focused,
              [styles['innerContainer--error']]: error
            }
          )}
        >
          <InputLabel label={label} className={formStyles.insideLabel} />
          <CarIcon icon={icon} size="28px" className={formStyles.iconField__iconEnlarged} />
          <Button
            className={styles.suggesterOpener}
            variant="text"
            onClick={handleMobileClick}
            text={selected && selected.name ? selected.name : placeholder}
          />
        </div>
      )}
      <OverlayWrapper
        classNameOuter={styles.suggesterWrapperOuter}
        className={styles.suggesterWrapper}
        classNameContent={styles.suggesterWrapperContent}
        onClose={handleMobileClose}
        closeBtn
        useClickOutside={false}
        open={mobileOpen}
        title={label}
        valign="top"
        inUse={isMobile}
        scrollMobileTop
      >
        {!insideLabel && label && <InputLabel label={label} />}
        <div
          className={classNames(
            'suggester_innerContainer',
            styles.innerContainer,
            formStyles.iconField__container,
            {
              [formStyles['iconField--focused']]: focused,
              [styles['innerContainer--error']]: error
            }
          )}
        >
          {insideLabel && label && <InputLabel label={label} className={formStyles.insideLabel} />}
          <CarIcon icon={icon} size="28px" className={formStyles.iconField__iconEnlarged} />

          {hasMounted && (
            <>
              <input
                id={idx}
                className={formStyles.iconField__inputEnlarged}
                name={idx}
                type="text"
                ref={inputEl}
                defaultValue={selected ? selected.name : ''}
                placeholder={placeholder}
                onClick={handleInputClick}
                onInput={handleInputKeyPress}
                onKeyDown={handleInputKeyDown}
                onFocus={handleInputFocus}
                onBlur={handleInputBlur}
              />

              <SlideUp open={!!error && (hover || focused)}>
                <ul className={classNames(formStyles.fieldErrors, 'fieldErrors')}>
                  <li>{t(error)}</li>
                </ul>
              </SlideUp>
            </>
          )}
        </div>

        {loading && <Spinner className={styles.spinner} size="small" />}

        <SlideUp open={expanded && items.length > 0}>
          <div className={styles.resultsContainer}>
            <Scrollbars ref={scrollbarsRef} autoHeight autoHeightMax={isMobile ? 500 : 300}>
              <ul ref={resultsRef} className={styles.resultsList}>
                {listItems.map((item, i) => (
                  <SuggesterRow
                    key={item.locationKey}
                    i={i}
                    index={index}
                    location={item}
                    level={item.level}
                    onClick={(el) => selectLocation(el, i)}
                    onMouseEnter={setIndex}
                  />
                ))}
              </ul>
            </Scrollbars>
          </div>
        </SlideUp>
      </OverlayWrapper>
    </div>
  );
};

export default memo(Suggester);
