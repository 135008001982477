import React from 'react';
import useTranslation from 'providers/translations/use-translations';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import OverlayWrapper from 'components/common/overlay-wrapper';
import commonstyles from 'components/common/common.module.scss';
import styles from './filters.module.scss';
import StationLocationFilter from './station/station-location-filter';
import TotalPriceFilter from './total-price/total-price-filter';
import DoorsAndPassengersFilter from './passengers/doors-and-passengers-filter';
import FuelPolicyFilter from './fuel-policy/fuel-policy-filter';
import PartnerFilterBox from './partner/partner-filter-box';
import CarClassFilterBox from './car-class/car-class-filter-box';
import CarScoreFilterBox from './car-score/car-score-filter-box';
import CarTypeFilterBox from './car-type/car-type-filter-box';
import EquipFilterBox from './equip/equip-filter-box';
import DepositBooleanFilterBox from './deposit/deposit-boolean-filter-box';

type Props = {
  onClick: (value: boolean) => void;
  disabled?: boolean;
  open?: boolean;
};

const FiltersSidebar: React.FC<Props> = ({ onClick, open = true, disabled = false }) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenDetect();

  if (!open) return null;
  return (
    <OverlayWrapper
      open={open}
      closeBtn
      inUse={isMobile}
      className={commonstyles.overlayWrapper__sidebar}
      onClose={() => onClick(false)}
    >
      <div className={styles.sidebar}>
        <StationLocationFilter disabled={disabled} label={t('filters_ltype_caption')} />

        <CarScoreFilterBox disabled={disabled} label={t('filters_car_score')} />

        <DepositBooleanFilterBox disabled={disabled} label={t('filters_deposit')} />

        <EquipFilterBox disabled={disabled} label={t('filters_equipment')} />
        <DoorsAndPassengersFilter
          label={t('filters_doors_and_passengers')}
          labelPax={t('filters_pax')}
          labelDoors={t('filters_doors')}
          disabled={disabled}
        />

        <FuelPolicyFilter disabled={disabled} label={t('filters_fuel_policy')} />

        {/* <TotalPriceFilter disabled={disabled} label={t('filters_prices')} /> */}

        <CarClassFilterBox disabled={disabled} label={t('filters_car_class')} />

        <CarTypeFilterBox disabled={disabled} label={t('filters_car_type')} />

        <PartnerFilterBox disabled={disabled} label={t('filters_partners')} />
      </div>
    </OverlayWrapper>
  );
};

export default FiltersSidebar;
