import UsedFilters from 'modules/filters/used-filters';
import useOffersContext from 'providers/offers/use-offers-context';
import useTranslation from 'providers/translations/use-translations';
import useCarsApi from 'providers/cars-api/use-cars-api';
import { currencyToSymbol, priceFormat, combineString } from 'src/utils/string';
import React, { FC, useEffect, useState } from 'react';
import styles from './offerlist.module.scss';
import classNames from 'classnames';

const OfferlistTopBar: FC = () => {
  const { searching, total, filtered, offers, filters } = useOffersContext();
  const { t } = useTranslation();
  const { general } = useCarsApi();
  const [priceMin, setPriceMin] = useState(0);
  const [priceMax, setPriceMax] = useState(0);

  useEffect(() => {
    let priceMinCandidate = 99999;
    let priceMaxCandidate = 0;
    offers.forEach((car) => {
      const price = car.priceExchanged > 0 ? car.priceExchanged : car.price;
      if (price < priceMinCandidate) {
        priceMinCandidate = price;
      }
      if (price > priceMaxCandidate) {
        priceMaxCandidate = price;
      }
    });
    setPriceMin(priceMinCandidate);
    setPriceMax(priceMaxCandidate);
  }, [offers]);

  return (
    <div className={classNames(styles.offerlistTopbar, 'offerlistTopbar')}>
      <div className={classNames(styles.offerlistTopbar__upper, 'offerlistTopbarUpper')}>
        {(searching || total === 0 || filtered === 0) && <div />}
        {!searching && total > 0 && filtered > 0 && (
          <div
            className={styles.offerlistTopbar__heading_1}
            dangerouslySetInnerHTML={{
              __html: combineString(t('we_have_found_x_offers'), [
                `<strong>${filtered}</strong>`,
                `<strong>${priceFormat(priceMin, 0)} ${currencyToSymbol(
                  general.currency
                )}</strong>`,
                `<strong>${priceFormat(priceMax, 0)} ${currencyToSymbol(general.currency)}</strong>`
              ])
            }}
          />
        )}
        <div className={classNames(styles.offerlistTopbar__heading_2, 'offerlistTopbarHeading2')}>
          <span>{filtered}</span>
          {filtered !== total && <span>{` / ${total}`}</span>}
          <span>{` ${t('0nc_offers')}`}</span>
        </div>
      </div>
      {filters.length > 0 && <UsedFilters className={styles.offerlistTopbar__usedFilters} />}
    </div>
  );
};

export default OfferlistTopBar;
