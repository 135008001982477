import React, { FC, memo, useEffect } from 'react';
import ReactDOM from 'react-dom';
import useState from 'react-usestateref';
import Datepicker from 'components/datepicker';
import Suggester from 'components/suggester';
import { Location } from 'components/suggester/types';
import Button from 'components/forms/button';
import useCarsApi from 'providers/cars-api/use-cars-api';
import { DateRange } from 'types/date-range.type';
import useTranslation from 'providers/translations/use-translations';
import useOffersContext from 'providers/offers/use-offers-context';
import CarIcon from 'components/common/car-icon';
import { sameLocations, sameSearch } from 'src/utils';
import classNames from 'classnames';
import InputLabel from 'components/forms/input-label';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import useHasMounted from 'hooks/use-has-mounted';
import useDevToolsContext from 'providers/dev-tools/use-dev-tools-context';
import { useRouter } from 'next/router';
import { getQueryParam, getRawQueryParam } from 'src/utils/url-tools';
import useNonInitialEffect from 'hooks/use-non-initial-effect';
import { SearchData } from 'types/search/search-data.type';
import moment from 'moment';
import { dayDiff, prepareDropOffDate, preparePickUpDate } from 'src/utils/date-time';
import { getLocationById } from 'src/page-params-initializer/subpage-data';
import { wsDebug, wsInfo } from 'src/logger';
import useTheme from 'providers/theme/use-theme';
import SameLocationToggle from './same-location-toggle';
import AgeSelect from './age-select';
import TimePicker from './time-picker';

import styles from './searchform.module.scss';

type Props = {
  search: SearchData;
  isSearchModified: (modified: boolean) => void;
};

const Searchform: FC<Props> = (props) => {
  const router = useRouter();
  const { t } = useTranslation();
  const {
    agent,
    general,
    globalEvents,
    watchEvents,
    subpage,
    setSubpage,
    clearCompare,
    notificationService
  } = useCarsApi();
  const { testMode, partners, customProxy } = useDevToolsContext();
  const [rawSearchId, setRawSearchId] = useState(getQueryParam('searchid'));
  const { settings } = useTheme();

  const { searchData, startSearch, clearSearch, searchId } = useOffersContext();
  const { isMobile } = useScreenDetect();
  const hasMounted = useHasMounted();
  const [sameLocation, setSameLocation, sameLocationRef] = useState(
    sameLocations(props.search?.location?.pickUp, props.search?.location?.dropOff, true)
  );
  const [pickUp, setPickUp, pickUpRef] = useState<Location>(props.search?.location?.pickUp);
  const [dropOff, setDropOff, dropOffRef] = useState<Location>(props.search?.location?.dropOff);
  const [dates, setDates] = useState<DateRange>({
    from: moment(props.search.dates.from),
    to: moment(props.search.dates.to)
  });
  const [age, setAge] = useState(props.search?.driverAge);
  const [searcherExpanded, setSearcherExpanded] = useState(true);

  const [pickUpError, setPickUpError] = useState<string | undefined>();
  const [dropOffError, setDropOffError] = useState<string | undefined>();

  const isValid = (silent = false): boolean => {
    setPickUpError(undefined);
    setDropOffError(undefined);
    let valid = true;

    if (!pickUpRef.current?.locationKey) {
      if (!silent) setPickUpError('pickup_loc_incorrect');
      valid = false;
    }

    if (!sameLocationRef.current && !dropOffRef.current?.locationKey) {
      if (!silent) setDropOffError('dropoff_loc_incorrect');
      valid = false;
    }

    if (!valid && !silent) {
      notificationService.createNotificationFromPreset('invalidSearchParams');
    }
    return valid;
  };

  const readStateFromUrl = async (): Promise<void> => {
    const fromDate = preparePickUpDate(getQueryParam('pckdate'), getQueryParam('pcktime'));
    const toDate = prepareDropOffDate(getQueryParam('drpdate'), getQueryParam('drptime'), fromDate);

    const pickUpLoc: Location = getQueryParam('pckloc')
      ? await getLocationById(getQueryParam('pckloc'), router.locale).catch(() => null)
      : null;
    const dropOffLoc: Location = getQueryParam('drploc')
      ? await getLocationById(getQueryParam('drploc'), router.locale).catch(() => null)
      : null;

    ReactDOM.unstable_batchedUpdates(() => {
      setRawSearchId(getQueryParam('searchid'));
      if (
        (dates.from && !dates.from.isSame(moment(fromDate))) ||
        (dates.to && !dates.to.isSame(moment(toDate)))
      ) {
        setDates({ from: moment(fromDate), to: moment(toDate) });
      }
      if (pickUp?.locationKey !== pickUpLoc?.locationKey) setPickUp(pickUpLoc);
      if (dropOff?.locationKey !== dropOffLoc?.locationKey) setDropOff(dropOffLoc);
      setSameLocation(sameLocations(pickUpLoc, dropOffLoc, true));
      setAge(Number(getQueryParam('driver_age') ?? '35'));
    });
  };

  const start = (silent = false, forceNewSearch = false, cancellSearch = true) => {
    if (!isValid(silent)) return;

    clearCompare();
    setSubpage(router.pathname.includes('offers-widget') ? 'offerswidget' : 'offerlist');
    if (isMobile) setSearcherExpanded(false);

    startSearch(
      {
        agentId: agent.agentId,
        location: { pickUp, dropOff: !sameLocation ? dropOff : null },
        dates,
        driverAge: age,
        driverCountry: general.cor,
        locale: general.locale,
        rentDays:
          dayDiff(
            moment(dates.from.format()).set('hour', 0),
            moment(dates.to.format()).set('hour', 0)
          ) || 1
      },
      forceNewSearch,
      cancellSearch,
      testMode,
      partners,
      customProxy
    ).then(
      () => null,
      () => {
        if (!silent) {
          notificationService.createNotificationFromPreset('invalidSearchParams');
        }
      }
    );
  };

  useEffect(() => {
    if (!isMobile) setSearcherExpanded(true);
    if (isMobile && (subpage === 'offerlist' || subpage === 'offerswidget')) {
      setSearcherExpanded(false);
    }
  }, [isMobile]);

  useEffect(() => {
    const subscribtion = globalEvents.subscribe({
      next(e) {
        if (['testModeChange', 'sessionExpired'].includes(e.type)) {
          wsDebug('debug', `Start search due to ${e.type}`);
          start(true, true);
        }

        if (e.type === 'revertSearch') {
          setPickUp(searchData.location.pickUp);
          setDropOff(searchData.location.dropOff);
          setDates(searchData.dates as DateRange);
          setAge(searchData.driverAge);
          props.isSearchModified(false);
        }
      }
    });
    return () => {
      subscribtion.unsubscribe();
    };
  }, [searchData]);

  useEffect(() => {
    const corChanges = watchEvents('corChanged').subscribe((e) => {
      const rawSearchid = getRawQueryParam('searchid');
      if (rawSearchid) {
        general.cor = e.data;
        setSubpage(router.pathname.includes('offers-widget') ? 'offerswidget' : 'offerlist');
        wsDebug('debug', 'Start search due to cor change');
        start(true, true, true);
      }
    });
    return () => {
      corChanges.unsubscribe();
    };
  }, [partners, general]);

  useEffect(() => {
    if (!hasMounted) return () => null;

    wsDebug('debug', 'Try to search on mounted');
    start(true, false, true);

    const handleRouteChange = () => {
      readStateFromUrl();
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [hasMounted]);

  useNonInitialEffect(() => {
    if (rawSearchId && rawSearchId !== searchId) {
      wsDebug('debug', 'Start search due to raw search id change', {
        rawSearchId,
        searchId
      });
      start(true, false, true);
    }
    if (!rawSearchId) {
      clearSearch();
      setSubpage('searchform');
    }
  }, [rawSearchId]);

  useEffect(() => {
    globalEvents.next({ type: 'pickupLocationChanged', data: pickUp });
  }, [pickUp]);

  useEffect(() => {
    props.isSearchModified(
      !sameSearch(searchData, {
        agentId: agent.agentId,
        location: { pickUp, dropOff },
        dates,
        driverAge: age,
        driverCountry: general.cor,
        locale: general.locale
      })
    );
  }, [pickUp, dropOff, dates, age, searchData, general]);
  return (
    <>
      {searcherExpanded && (
        <div className={styles.outerContainer}>
          {isMobile && (subpage === 'offerlist' || subpage === 'offerswidget') && (
            <div className={styles.selected__edit_container}>
              <Button
                className={styles.selected__edit}
                variant="text"
                size="medium"
                noUnderline
                onClick={() => setSearcherExpanded(false)}
              >
                <CarIcon icon="pencil" size="20px" />
              </Button>
            </div>
          )}
          <div className={classNames(styles.searchForm, 'searchform_searchForm')}>
            <div className={styles.sameLocationWrapper}>
              <SameLocationToggle sameLocation={sameLocation} onChange={setSameLocation} />
            </div>
            <div className={styles.ageSelect}>
              <AgeSelect age={age} onChange={setAge} scrollMobileTop />
            </div>

            <div
              className={classNames(
                styles.locationWrapper,
                'searchform_locationWrapper',
                sameLocation ? styles.sameLocation : undefined
              )}
            >
              <Suggester
                label={t('0nc_pickup')}
                insideLabel={!!isMobile}
                classNames={['pickupSuggester']}
                value={pickUp}
                error={pickUpError}
                onChange={(loc) => {
                  setPickUp(loc);
                  if (loc?.locationKey) setPickUpError(undefined);
                  if (sameLocation && dropOff) {
                    setDropOff(null);
                    setDropOffError(undefined);
                  }
                }}
                placeholder={t('0nc_pickup')}
              />
              {!sameLocation && (
                <Suggester
                  label={t('0nc_return')}
                  classNames={['returnSuggester']}
                  insideLabel={!!isMobile}
                  value={dropOff}
                  error={dropOffError}
                  onChange={(loc) => {
                    setDropOff(loc);
                    if (loc?.locationKey) setDropOffError(undefined);
                  }}
                  placeholder={t('0nc_return')}
                  icon="b"
                />
              )}
            </div>
            <div className={styles.datepickerWrapper}>
              {!isMobile && <InputLabel label={t('rental_period')} />}
              <Datepicker
                value={dates}
                labels={[t('drel_pickup_date'), t('drel_return_date'), t('rental_period')]}
                minDays={settings.searchform.minDaysToBooking || 1}
                onChange={setDates}
              />
            </div>
            <div className={styles.timePickerWrapper}>
              <TimePicker value={dates} onChange={setDates} sameDayCheck scrollMobileTop />
            </div>
            <div className={styles.searchButtonWrapper}>
              <div className={styles.searchButtonOuterContainer}>
                <InputLabel emptyLabel />
                <Button
                  className={classNames(styles.searchButton, 'searchform_searchButton')}
                  variant="primary"
                  size="large"
                  onClick={() => {
                    wsInfo('userInteraction', 'Search button clicked');
                    start(false, true, true);
                  }}
                >
                  <CarIcon icon="search" size="28px" />
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!searcherExpanded && (
        <div className={classNames(styles.outerContainer, 'searchform__outerContainer')}>
          <div className={styles.selected__locations}>
            <span className={styles.selected__location}>
              {pickUp?.name}
              <span className={styles.selected__date}>
                {dates.from.locale(router.locale).format('ddd DD.MM, ')}
                {dates.from.locale(router.locale).format('HH:mm')}
              </span>
            </span>
            <CarIcon icon="arrow-right" />
            <span className={styles.selected__location}>
              {sameLocation && pickUp?.name}
              {!sameLocation && dropOff?.name}
              <span className={styles.selected__date}>
                {dates.to.locale(router.locale).format('ddd DD.MM, ')}
                {dates.to.locale(router.locale).format('HH:mm')}
              </span>
            </span>
            <Button
              className={styles.selected__edit}
              variant="text"
              size="medium"
              noUnderline
              onClick={() => setSearcherExpanded(true)}
            >
              <CarIcon icon="pencil" size="20px" />
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
export default memo(Searchform);
