import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC } from 'react';
import classNames from 'classnames';
import useTranslation from 'providers/translations/use-translations';
import { ArrayFilter, ArrayNumberFilter } from 'src/filters/types';
import { CarClass } from 'types/car-class.type';
import { CarType } from 'types/car-type.type';
import { CarEquip } from 'types/car-equip.type';
import { CarScore } from 'types/car-score.type';
import { StationType } from 'src/stations/types';
import { stationTypeMap } from 'src/stations/station-tools';
import CarIcon from 'components/common/car-icon';
import Button from 'components/forms/button';
import StationUsedFilter from './station/station-used-filter';
import styles from './filters.module.scss';
import TotalPriceUsedFilter from './total-price/total-price-used-filter';
import PassengersUsedFilter from './passengers/passengers-used-filter';
import DoorsUsedFilter from './passengers/doors-used-filter';
import FuelUsedFilter from './fuel-policy/fuel-used-filter';
import PartnerUsedFilter from './partner/partner-used-filter';
import CarClassUsedFilter from './car-class/car-class-used-filter';
import CarTypeUsedFilter from './car-type/car-type-used-filter';
import EquipUsedFilter from './equip/equip-used-filter';
import PickupStationUsedFilter from './pickup-location/pickup-station-used-filter';
import CarScoreUsedFilter from './car-score/car-score-used-filter';
import DepositUsedFilter from './deposit/deposit-used-filter';
import DepositBooleanUsedFilter from './deposit/deposit-boolean-used-filter';

type Props = {
  className?: string;
};

const UsedFilters: FC<Props> = ({ className = undefined }) => {
  const { filters, clearFilters } = useOffersContext();
  const { t } = useTranslation();
  const equipFilterLabels = {
    ac: t('filters_ac'),
    manualTransmission: t('filters_gear_m'),
    automaticTransmission: t('filters_gear_a'),
    gps: t('ac_extras_nev'),
    electric: t('filters_eh'),
    drive4W: t('filters_4_wheel'),
    unlimitedkm: t('filters_unlimited_km'),
    prepaid: t('prepaid'),
    winterTires: t('a_winter_tires'),
    additionalDriver: t('additional_driver')
  };

  return (
    <ul className={classNames(styles.usedFilters, className)}>
      {filters.map((filter) => {
        switch (filter.type) {
          case 'station':
            return (
              <StationUsedFilter
                key={filter.type}
                filter={filter as ArrayFilter<StationType>}
                labels={stationTypeMap}
              />
            );
          case 'pickupStation':
            return (
              <PickupStationUsedFilter
                key={filter.type}
                filter={filter}
                label={t('drel_pickup_loc')}
              />
            );
          case 'totalPrice':
            return (
              <TotalPriceUsedFilter key={filter.type} filter={filter} label={t('filters_prices')} />
            );
          case 'passengers':
            return <PassengersUsedFilter key={filter.type} filter={filter} />;

          case 'doors':
            return <DoorsUsedFilter key={filter.type} filter={filter} />;

          case 'fuel':
            return (
              <FuelUsedFilter key={filter.type} filter={filter} label={t('filters_fuel_policy')} />
            );

          case 'partner':
            return <PartnerUsedFilter key={filter.type} filter={filter} />;

          case 'class':
            return (
              <CarClassUsedFilter key={filter.type} filter={filter as ArrayFilter<CarClass>} />
            );

          case 'carType':
            return <CarTypeUsedFilter key={filter.type} filter={filter as ArrayFilter<CarType>} />;

          case 'equip':
            return (
              <EquipUsedFilter
                key={filter.type}
                filter={filter as ArrayFilter<CarEquip>}
                labels={equipFilterLabels}
              />
            );
          case 'deposit':
            return (
              <DepositUsedFilter
                key={filter.type}
                filter={filter as ArrayNumberFilter<number>}
                label={t('filter_max_deposit')}
              />
            );

          case 'hasDeposit':
            return (
              <DepositBooleanUsedFilter
                key={filter.type}
                filter={filter as ArrayFilter<string>}
                label={t('filters_deposit')}
              />
            );

          case 'carScore':
            return (
              <CarScoreUsedFilter key={filter.type} filter={filter as ArrayFilter<CarScore>} />
            );

          default:
            return null;
        }
      })}
      {filters.length > 0 && (
        <li>
          <Button variant="secondary" className={styles.clearBtn} onClick={clearFilters}>
            {t('0nc_reset_filters')}
            <CarIcon icon="trash-empty" size="1em" />
          </Button>
        </li>
      )}
    </ul>
  );
};

export default UsedFilters;
