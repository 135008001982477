import useOffersContext from 'providers/offers/use-offers-context';
import useTranslation from 'providers/translations/use-translations';
import React, { FC } from 'react';
import { ArrayFilter } from 'src/filters/types';
import { CarClass } from 'types/car-class.type';
import UsedFilterWrapper from '../used-filter-wrapper';

type Props = {
  filter: ArrayFilter<CarClass>;
};

const CarClassUsedFilter: FC<Props> = ({ filter }) => {
  const { t } = useTranslation();
  const { updateFilter } = useOffersContext();
  if (filter.value.length === 0) return null;

  const remove = (carClass: CarClass) => {
    updateFilter({
      type: 'class',
      value: filter.value.filter((item) => item !== carClass)
    });
  };

  return (
    <>
      {filter.value.map((carClass) => (
        <UsedFilterWrapper key={carClass} onClear={() => remove(carClass)}>
          {t(carClass)}
        </UsedFilterWrapper>
      ))}
    </>
  );
};

export default CarClassUsedFilter;
