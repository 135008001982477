import React, { FC, ReactNode, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';

import styles from './stations-map.module.scss';

type Props = {
  children: ReactNode;
  initialBottomOffset?: number;
  initialLeftOffset?: number;
  anchorX?: number;
  anchorY?: number;
  onOverEvent?: (over: boolean) => void;
};

const WindowWrapper: FC<Props> = ({
  children,
  initialBottomOffset = 0,
  initialLeftOffset = 0,
  anchorX = 0,
  anchorY = 0,
  onOverEvent = () => null
}) => {
  const ref = useRef();
  const [bottomOffset, setBottomOffset] = useState(0);
  const [leftOffset, setLeftOffset] = useState(0);

  useEffect(() => {
    setLeftOffset(-Math.round(get(ref, 'current.offsetWidth', 0) / 2) + initialLeftOffset);
    const upperOverflow = initialBottomOffset - anchorY + get(ref, 'current.offsetHeight', 0);
    setBottomOffset(initialBottomOffset - (upperOverflow > 0 ? upperOverflow : 0));
  }, [ref]);

  return (
    <div
      ref={ref}
      className={styles.markerWindow}
      style={{ left: `${leftOffset}px`, bottom: `${bottomOffset}px` }}
      onMouseEnter={() => {
        onOverEvent(true);
      }}
      onMouseLeave={() => {
        onOverEvent(false);
      }}
    >
      {children}
    </div>
  );
};

export default WindowWrapper;
