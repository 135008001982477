import React, { FC, memo } from 'react';
import { Moment } from 'moment';
import { useRouter } from 'next/router';

import { dayDiff } from 'src/utils/date-time';
import useTranslation from 'providers/translations/use-translations';
import styles from './datepicker.module.scss';

type Props = {
  mode: 'from' | 'to';
  from: Moment;
  to: Moment;
};

const CurrentSelection: FC<Props> = ({ mode, from, to }) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  return (
    <p className={styles.pickerSelection}>
      <strong className={mode === 'from' ? styles.pickerSelection__current : undefined}>
        {from.locale(locale).format('ddd, DD MMM YYYY')}
      </strong>
      <span> - </span>
      <strong className={mode === 'to' ? styles.pickerSelection__current : undefined}>
        {`${to.locale(locale).format('ddd, DD MMM YYYY')} `}
      </strong>
      <span>{`(${t('rental_period', { count: dayDiff(from, to) })})`}</span>
    </p>
  );
};

export default memo(CurrentSelection);
