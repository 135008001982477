import React, { FC, useEffect, useState } from 'react';
import Button from 'components/forms/button';
import classNames from 'classnames';
import { ArrayFilter } from 'src/filters/types';
import { Navigation, A11y, Grid } from 'swiper/modules';
// https://github.com/import-js/eslint-plugin-import/issues/2266
// eslint-disable-next-line import/no-unresolved
import { Swiper, SwiperSlide } from 'swiper/react';
import useOffersContext from 'providers/offers/use-offers-context';
import useCarsApi from 'providers/cars-api/use-cars-api';
import { CLASS_LIST } from 'modules/filters/car-class/car-class-filter-box';
import styles from './category-slider.module.scss';
import CategorySliderItem from './item';

// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/a11y';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/grid';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';

type Props = {};

const CategorySlider: FC<Props> = () => {
  const { general } = useCarsApi();
  const { updateFilter, pricesStartsFrom, allOffers, filters } = useOffersContext();
  const [swiper, setSwiper] = useState<any>();
  const [slides, setSlides] = useState([]);

  const getOfferById = (id: string) => {
    if (id && allOffers.length > 0) {
      return allOffers.find((offer) => offer.offerId === id);
    }
    return undefined;
  };

  const checkIfActive = (category) => {
    const index = filters.findIndex((filter) => filter.type === 'class');
    if (index > -1) {
      if ((filters[index] as ArrayFilter).value.indexOf(category) > -1) {
        return true;
      }
    }
    return false;
  };

  const prepareSlides = () => {
    const newSlides = [];
    CLASS_LIST.forEach((type) => {
      if (
        pricesStartsFrom &&
        pricesStartsFrom['class'] &&
        pricesStartsFrom['class'][type] &&
        pricesStartsFrom['class'][type].offerId &&
        pricesStartsFrom['class'][type].price
      ) {
        newSlides.push({
          offerId: pricesStartsFrom['class'][type].offerId,
          offer: getOfferById(pricesStartsFrom['class'][type].offerId),
          price: pricesStartsFrom['class'][type].price,
          currency: general.currency,
          category: type
        });
      }
    });
    return newSlides;
  };

  const filterChange = (slide) => {
    // removeFilter('class');
    updateFilter({
      type: 'class',
      value: [slide.category]
    });
  };

  useEffect(() => {
    setSlides(prepareSlides());
  }, [pricesStartsFrom]);

  useEffect(() => {
    if (swiper && swiper.destroyed !== true) {
      const currentSlidesPerView =
        swiper.params.breakpoints[swiper.currentBreakpoint].slidesPerView;
      if (slides.length < currentSlidesPerView) {
        for (let i = slides.length; i < currentSlidesPerView; i += 1) {
          slides.push({});
        }
      }
    }
  }, [slides]);

  return (
    <>
      {slides.length > 0 && (
        <div className={styles.categorySlider}>
          <Swiper
            modules={[Navigation, A11y, Grid]}
            breakpoints={{
              0: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: slides && slides.length < 3 ? slides.length : 3
              },
              950: {
                slidesPerView: slides && slides.length < 4 ? slides.length : 4
              },
              1150: {
                slidesPerView: slides && slides.length < 5 ? slides.length : 5
              }
            }}
            spaceBetween={10}
            navigation
            pagination={{ clickable: true }}
            onSwiper={(swp) => setSwiper(swp)}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={slide.offerId || index}>
                {slide.offer && (
                  <Button
                    onClick={() => filterChange(slide)}
                    className={styles.categorySlider__button}
                  >
                    <CategorySliderItem
                      price={slide.price}
                      currency={slide.currency}
                      offer={slide.offer}
                      category={slide.category}
                      className={classNames({
                        [styles['categorySlider__item--active']]: checkIfActive(slide.category)
                      })}
                    />
                  </Button>
                )}
                <span />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </>
  );
};

export default CategorySlider;
