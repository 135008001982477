import React, { FC } from 'react';
import useOffersContext from 'providers/offers/use-offers-context';
import { PriceFilter } from 'src/filters/types';
import useCarsApi from 'providers/cars-api/use-cars-api';
import { currencyToSymbol } from 'src/utils/string';
import UsedFilterWrapper from '../used-filter-wrapper';

import styles from '../filters.module.scss';

type Props = {
  filter: PriceFilter;
  label?: string;
};

const TotalPriceUsedFilter: FC<Props> = ({ filter, label }) => {
  const { general } = useCarsApi();
  const { removeFilter } = useOffersContext();

  const remove = () => {
    removeFilter('totalPrice');
  };

  return (
    <UsedFilterWrapper onClear={remove}>
      {label && <span className={styles.usedFilters__label}>{label}</span>}
      <span className={styles.usedFilters__value}>
        {`${filter.value.min} - ${filter.value.max} `}
        <span
          dangerouslySetInnerHTML={{
            __html: currencyToSymbol(general.currency)
          }}
        />
      </span>
    </UsedFilterWrapper>
  );
};

TotalPriceUsedFilter.defaultProps = {
  label: undefined
};

export default TotalPriceUsedFilter;
