import BlockMessage, { MessageType } from 'components/block-message';
import CarIcon from 'components/common/car-icon';
import Spinner from 'components/common/spinner';
import Button from 'components/forms/button';
import FiltersSidebar from 'modules/filters/filters-sidebar';
import { useRouter } from 'next/router';
import { get } from 'lodash';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import SingleOffer from 'modules/offer/single-offer';
import SingleWidgetOffer from 'modules/offer/single-widget-offer';
import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Order } from 'types/engine/order.type';
import ProgressBar from 'components/common/progress-bar';
import useTranslation from 'providers/translations/use-translations';
import CompareBar from 'components/car-compare/compare-bar';
import useCarsApi from 'providers/cars-api/use-cars-api';
import CompareWrapperBtn from 'components/car-compare/compare-wrapper-btn';
import StationsMap from 'modules/maps/stations-map';
import { compareCarOrder } from 'src/cars/car-tools';
import { Tooltip } from 'react-tooltip';
import { sameLocations } from 'src/utils';
import stylesTooltip from 'components/common/react-tooltip-common.module.scss';
import OverlayWrapper from 'components/common/overlay-wrapper';
import CategorySlider from 'components/category-slider';
import { wsInfo } from 'src/logger';
import styles from './offerlist.module.scss';
import OfferlistTopBar from './offerlist-top-bar';
import useBanners from 'providers/banners/use-banners-context';
import moment from 'moment';
import useTracking from 'providers/tracking/use-tracking';
import {
  defaultDateFormat,
  euDateFormat,
  jaDateFormat,
  usDateFormat
} from 'providers/tracking/tools';

const Offerlist: FC = () => {
  const {
    searchData,
    searching,
    progress,
    offers,
    total,
    filtered,
    inCompareMode,
    updateInCompareMode
  } = useOffersContext();

  const { triggerPricelist } = useTracking();

  const { getBanners } = useBanners();
  const { locale } = useRouter();
  const [showMap, setShowMap] = useState(false);
  const [order] = useState<Order>({ by: 'price', order: 'asc' });
  const { compare, subpage, general } = useCarsApi();
  const isOffersWidget = subpage === 'offerswidget';
  const OFFERS_PER_PAGE = isOffersWidget ? 5 : 20;
  const [limit, setLimit] = useState(OFFERS_PER_PAGE);
  const { isMobile } = useScreenDetect();
  const [filtersExpanded, setFiltersExpanded] = useState(!isMobile);
  const { t } = useTranslation();
  const [isMounted, setIsMounted] = useState(false);
  const router = useRouter();
  const offerMatchId = get(router.query, 'matchid', '');

  const matchedOffer = offers.find((offer) => offer.matchId === offerMatchId);

  const toggleMap = () => {
    setShowMap(!showMap);
    if (!showMap) wsInfo('userInteraction', 'Open offerlist map');
  };

  useEffect(() => {
    setFiltersExpanded(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (inCompareMode === true) document.body.classList.add('inCompareMode');
    if (inCompareMode === false) document.body.classList.remove('inCompareMode');
  }, [inCompareMode]);

  useEffect(() => {
    if (searching === true) {
      let apt = '';
      let country = '';
      if (searchData?.location?.pickUp) {
        const split = searchData.location.pickUp.locationKey.split('.');
        country = split[1];
        apt = split.slice(-1)[0];
      }
      getBanners({
        agent: searchData.agentId,
        IBEStep: subpage === 'searchform' ? 'search' : 'pricelist',
        apt,
        country,
        pickupDate: moment(searchData.dates.from).locale(locale).format('YYYY-MM-DD')
      });

      triggerPricelist({
        language: searchData?.locale,
        pickup_date: defaultDateFormat(searchData?.dates?.from),
        pickup_date_eu: euDateFormat(searchData?.dates?.from),
        pickup_date_ja: jaDateFormat(searchData?.dates?.from),
        pickup_date_us: usDateFormat(searchData?.dates?.from),
        pickup_place: searchData?.location?.pickUp?.locationKey,
        rental_days: searchData?.rentDays,
        return_date: defaultDateFormat(searchData?.dates?.to),
        return_date_eu: euDateFormat(searchData?.dates?.to),
        return_date_ja: jaDateFormat(searchData?.dates?.to),
        return_date_us: usDateFormat(searchData?.dates?.to),
        return_place: searchData?.location?.dropOff?.locationKey ?? ''
      });
    }
  }, [searching]);

  const messageType = (): MessageType => {
    if (searching) return '';
    if (total === 0) return 'noResults';
    if (filtered === 0) return 'filteredOut';
    return '';
  };

  return (
    <div
      className={classNames(
        styles.offerlistOuterContainer,
        'offerlistOuterContainer',
        'negativeMarginWithDynamicBg'
      )}
    >
      {searching && <ProgressBar className={styles.progressBar} progress={progress} />}
      <div
        className={classNames(
          styles.offerlistContainer,
          isMobile ? styles.isMobile : styles.notMobile,
          isOffersWidget ? styles.offersWidgetContainer : undefined
        )}
      >
        {searching && <Spinner />}
        {total > 0 && !inCompareMode && !isOffersWidget && (
          <div className={styles.sidebar}>
            <div className={styles.top_button__container}>
              {sameLocations(searchData?.location?.pickUp, searchData?.location?.dropOff, true) && (
                <Button
                  className={classNames(styles.mapBtn, 'offerlist_mapBtn')}
                  variant="secondary"
                  onClick={toggleMap}
                  disabled={searching}
                >
                  <CarIcon icon="map" size="16px" />
                  <span>{t('0nc_map_view')}</span>
                </Button>
              )}

              {isMobile && (
                <>
                  <div className={styles.top_button__spacer} />
                  <Button
                    variant="secondary"
                    onClick={() => updateInCompareMode(!inCompareMode)}
                    className={classNames(styles.compareModeBtn, 'offerlist_compareModeBtn')}
                  >
                    <CarIcon icon="balance-scale" size="16px" />
                    {t('drel_comparison')}
                  </Button>
                </>
              )}

              {isMobile && (
                <Button
                  variant="primary"
                  onClick={() => setFiltersExpanded(!filtersExpanded)}
                  className={classNames(styles.filterBtn, 'offerlist_filterBtn')}
                >
                  <CarIcon icon="filter" size="16px" />
                  {t('0nc_filters')}
                </Button>
              )}
            </div>
            {filtersExpanded && (
              <FiltersSidebar onClick={setFiltersExpanded} disabled={searching} />
            )}
          </div>
        )}
        {isMobile && inCompareMode && <CompareBar offers={compare} />}
        {!inCompareMode && <OfferlistTopBar />}

        <div
          className={classNames(
            styles.offerlist,
            inCompareMode ? styles.inCompareMode : undefined,
            styles.offerlist__offersWidget ? isOffersWidget : undefined
          )}
        >
          {!searching && showMap && !isOffersWidget && (
            <OverlayWrapper
              open
              inUse={isMobile}
              closeBtn
              title={t('0nc_map_view')}
              onClose={() => setShowMap(false)}
            >
              <StationsMap close={() => setShowMap(false)} />
            </OverlayWrapper>
          )}

          {!inCompareMode && !isOffersWidget && !searching && offers.length > 0 && (
            <CategorySlider />
          )}

          <ul
            className={classNames(
              styles.offerlist__offers,
              isOffersWidget ? styles.offerlist__offers__offersWidget : undefined
            )}
          >
            {matchedOffer && (
              <li key={matchedOffer.offerId}>
                <CompareWrapperBtn inUse={isMobile && inCompareMode} offer={matchedOffer}>
                  {isOffersWidget ? (
                    <SingleWidgetOffer offer={matchedOffer} />
                  ) : (
                    <span className={styles.matchedOffer__wrapper}>
                      <span className={styles.matchedOffer__heading}>
                        {t('matched_offer_heading')}
                      </span>
                      <SingleOffer offer={matchedOffer} className={styles.matchedOffer} />
                    </span>
                  )}
                </CompareWrapperBtn>
              </li>
            )}
            {offers
              .filter((offer) => offer.matchId !== offerMatchId)
              .sort((a, b) => compareCarOrder(a, b, order))
              .slice(0, limit)
              .map((offer, index) => (
                <li key={offer.offerId}>
                  <CompareWrapperBtn
                    tabIndex={index}
                    inUse={isMobile && inCompareMode}
                    offer={offer}
                  >
                    {isOffersWidget && <SingleWidgetOffer offer={offer} />}
                    {!isOffersWidget && <SingleOffer offer={offer} />}
                  </CompareWrapperBtn>
                </li>
              ))}
          </ul>
          <BlockMessage className={styles.messageBlock} type={messageType()} />
          {isMounted && (
            <Tooltip
              id="tooltip_price_info"
              variant="light"
              arrowColor="var(--yps-tooltip-border)"
              className={classNames(stylesTooltip.tooltip, stylesTooltip.tooltip__priceInfo)}
            >
              <div>{t('price_info')}</div>
            </Tooltip>
          )}
          {isMounted && (
            <Tooltip
              id="offer_advantages_info"
              variant="light"
              arrowColor="var(--yps-tooltip-border)"
              className={classNames(stylesTooltip.tooltip, stylesTooltip.tooltip__advantagesInfo)}
            />
          )}
          {isMounted && (
            <Tooltip
              id="offer_scoring_info"
              variant="light"
              arrowColor="var(--yps-tooltip-border)"
              className={classNames(stylesTooltip.tooltip, stylesTooltip.tooltip__scoringInfo)}
            />
          )}
          {isMounted && (
            <Tooltip
              id="offer_discount_info"
              variant="light"
              arrowColor="var(--yps-tooltip-border)"
              className={classNames(stylesTooltip.tooltip, stylesTooltip.tooltip__discountInfo)}
            />
          )}
          {isMounted && (
            <Tooltip
              id="offer_status_info"
              variant="light"
              arrowColor="var(--yps-tooltip-border)"
              className={classNames(stylesTooltip.tooltip, stylesTooltip.tooltip__offerStatusInfo)}
            />
          )}

          {isMounted && (
            <Tooltip
              id="offer_prepaid_info"
              variant="light"
              arrowColor="var(--yps-tooltip-border)"
              className={classNames(stylesTooltip.tooltip, stylesTooltip.tooltip__offerPrepaidInfo)}
              place="bottom"
            />
          )}
        </div>

        {filtered > limit && (
          <Button
            className={styles.moreButton}
            variant="secondary"
            onClick={() => setLimit(limit + OFFERS_PER_PAGE)}
          >
            <span>{`${t('0nc_load_more_offers')} (${filtered - limit})`}</span>
            <CarIcon icon="arrow-down" />
          </Button>
        )}
      </div>
    </div>
  );
};

export default Offerlist;
