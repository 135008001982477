import PartnerLogo from 'components/common/partner-logo';
import Button from 'components/forms/button';
import useTranslation from 'providers/translations/use-translations';
import React, { FC } from 'react';

import styles from '../stations-map.module.scss';
import { StationData } from '../types';

interface Props {
  station: StationData;
  setStationFilter?: (ids: Array<string>) => void;
}

const StationWindow: FC<Props> = ({ station, setStationFilter = () => null }) => {
  const { t } = useTranslation();
  const setFilter = () => {
    setStationFilter([station.id]);
  };
  return (
    <div className={styles.stationDetails}>
      <span className={styles.stationDetails__name}>{station.name}</span>
      <PartnerLogo name={station.partner} label="partner" />

      <Button onClick={setFilter}>{t('0nc_choose_this_station')}</Button>
    </div>
  );
};

export default StationWindow;
