import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC } from 'react';
import { FuelFilter } from 'src/filters/types';
import useTranslation from 'providers/translations/use-translations';
import UsedFilterWrapper from '../used-filter-wrapper';
import { FUEL_TRANSLATIONS_MAP } from './fuel-policy-filter';

import styles from '../filters.module.scss';

type Props = {
  filter: FuelFilter;
  label: string;
};

const FuelUsedFilter: FC<Props> = ({ filter, label }) => {
  const { removeFilter } = useOffersContext();
  const { t } = useTranslation();
  const remove = () => {
    removeFilter('fuel');
  };

  return (
    <UsedFilterWrapper onClear={() => remove()}>
      <span className={styles.usedFilters__label}>{`${label}:`}</span>
      <span className={styles.usedFilters__value}>
        {filter.value.policy.map((item) => t(FUEL_TRANSLATIONS_MAP[item])).join(',')}
      </span>
    </UsedFilterWrapper>
  );
};

export default FuelUsedFilter;
