import React, { FC, useEffect, useRef, useState } from 'react';
import { get } from 'lodash';
import { Map, Point } from 'pigeon-maps';
import { coordinatesToLatLong, stationToData } from 'src/utils/data-conversion';
import useOffersContext from 'providers/offers/use-offers-context';
import { carMeetsFilters } from 'src/cars/car-tools';
import StationClusters from './station-clusters';
import { FitMapResult, StationData } from '../types';
import { fitMap, stationHasCoords } from '../tools';

type Props = {
  initialZoom?: number;
  height?: number;
  close?: () => void;
};

const StationsMap: FC<Props> = ({ initialZoom = 11, height = 500, close = () => null }) => {
  const mapRef = useRef(null);
  const search = {}; // TODO - dynamic value
  const { stations, offers, filters, updateFilter } = useOffersContext();
  const [ready, setReady] = useState(false);
  const [stationsData, setStationsData] = useState<Array<StationData>>([]);
  const [zoom, setZoom] = useState<number>(initialZoom);
  const [center, setCenter] = useState<Point>(
    coordinatesToLatLong(get(search, 'location.pickUp.center'))
  );

  const tilerProvider = (x, y, z) => {
    const s = String.fromCharCode(97 + ((x + y + z) % 3));
    return `https://${s}.tile.openstreetmap.org/${z}/${x}/${y}.png`;
  };

  const setStationFilter = (ids: Array<string>) => {
    updateFilter({
      type: 'pickupStation',
      value: ids
    });
    close();
  };

  useEffect(() => {
    if (stations.length === 0) {
      setStationsData([]);
      setCenter(coordinatesToLatLong(get(search, 'location.pickUp.center')));
      return;
    }
    setStationsData(
      stations.filter(stationHasCoords).map((station) => {
        const cars = station.cars.filter((offer) => carMeetsFilters(offer, filters));
        return stationToData(station, cars);
      })
    );

    const data: FitMapResult = fitMap(
      stations.filter(stationHasCoords).map((station) => coordinatesToLatLong(station.geo)),
      get(mapRef, 'current.offsetWidth', 0),
      get(mapRef, 'current.offsetHeight', 0)
    );
    if (data) {
      setZoom(data.zoom);
      setCenter(data.center);
    }
    if (!ready) setReady(true);
  }, [stations, offers, filters]);

  // if ( // TODO - add dynamic search
  //   !search ||
  //   (search.location.dropOff &&
  //     search.location.pickUp.locationKey !== search.location.dropOff.locationKey)
  // ) {
  //   return null;
  // }

  return (
    <div ref={mapRef}>
      <Map height={height} center={center} zoom={zoom} provider={tilerProvider}>
        {ready && (
          <StationClusters stationsData={stationsData} setStationFilter={setStationFilter} />
        )}
      </Map>
    </div>
  );
};

export default StationsMap;
