import React, { FC, useEffect, useState } from 'react';
import { clone } from 'lodash';
import CarIcon from 'components/common/car-icon';
import { sameArrays } from 'src/utils/array';
import CheckboxButton from 'components/forms/checkbox-button';
import useTranslation from 'providers/translations/use-translations';
import Price from 'components/common/price';
import { ArrayFilter } from 'src/filters/types';
import { StationType } from 'src/stations/types';
import { stationTypeMap } from 'src/stations/station-tools';
import useOffersContext from 'providers/offers/use-offers-context';
import useCarsApi from 'providers/cars-api/use-cars-api';
import FilterBox from '../filter-box';

import styles from '../filters.module.scss';

type Props = {
  label?: string;
  disabled?: boolean;
};

const FILTER_VALUES = ['airport', 'terminal', 'shuttle', 'rail', 'city'];

const StationLocationFilter: FC<Props> = ({ label = undefined, disabled = false }) => {
  const { t } = useTranslation();
  const { filters, updateFilter, pricesStartsFrom } = useOffersContext();
  const { general } = useCarsApi();

  const [selected, setSelected] = useState<Array<StationType>>([]);

  const selectionChange = (type: StationType, checked: boolean) => {
    if (checked) {
      selected.push(type);
    } else {
      selected.splice(selected.indexOf(type), 1);
    }
    setSelected(clone(selected));
    updateFilter({
      type: 'station',
      value: clone(selected)
    });
  };

  useEffect(() => {
    const filter = filters.find((item) => item.type === 'station') as ArrayFilter<StationType>;
    if (!filter && selected.length !== 0) {
      setSelected([]);
    }

    if (filter && !sameArrays(selected, filter.value)) {
      setSelected(filter.value);
    }
  }, [filters, selected]);

  return (
    <FilterBox title={label}>
      {FILTER_VALUES.map((type: StationType) => (
        <CheckboxButton
          key={type}
          value={selected.indexOf(type) !== -1}
          onChange={(checked) => selectionChange(type, checked)}
          labelClassName={styles.stationFilter}
          disabled={disabled || !pricesStartsFrom['station'] || !pricesStartsFrom['station'][type]}
        >
          <span>
            <CarIcon icon={stationTypeMap[type].icon} size="22px" />
            <span className={styles.stationFilter__label}>{t(stationTypeMap[type].textId)}</span>
          </span>
          {selected.indexOf(type) < 0 &&
            !disabled &&
            pricesStartsFrom['station'] &&
            pricesStartsFrom['station'][type] && (
              <span className={styles.price}>
                {t('price_from')}
                <Price
                  round
                  className={styles.offerPrice}
                  price={pricesStartsFrom['station'][type].price}
                  currency={general.currency}
                />
              </span>
            )}
        </CheckboxButton>
      ))}
    </FilterBox>
  );
};

export default StationLocationFilter;
