import CarIcon from 'components/common/car-icon';
import React, { ReactNode } from 'react';
import { SuggesterItem } from './types';

// Get location label
export const getLocationlabel = (item: SuggesterItem): ReactNode => <>{item.location}</>;

// Get location icon
export const getLocationIcon = (type: string): ReactNode => {
  switch (type) {
    case 'AIRP':
      return <CarIcon size="18px" icon="airport" />;
    case 'RSTN':
      return <CarIcon size="18px" icon="train-station" />;
    case 'city':
    default:
      return <CarIcon size="18px" icon="city" />;
  }
};

// Get location details (parents)
export const getLocationDetails = (location: SuggesterItem): ReactNode => {
  const items = [];
  if (location.country) items.push(location.country);
  if (location.region) items.push(location.region);

  return items.join(' - ');
};
