import React, { FC, useEffect, useState } from 'react';
import useTranslation from 'providers/translations/use-translations';

import useCarsApi from 'providers/cars-api/use-cars-api';
import Price from 'components/common/price';
import styles from '../stations-map.module.scss';
import StationClusterWindow from './station-cluster-window';
import { PigeonProps, StationClusterData } from '../types';
import WindowWrapper from '../window-wrapper';

interface MarkerProps extends PigeonProps {
  data: StationClusterData;
  size?: number;
  hover?: boolean;
  setStationFilter?: (ids: Array<string>) => void;
}

const StationClusterMarker: FC<MarkerProps> = ({
  data,
  size = 30,
  hover = false,
  setStationFilter = () => null,
  ...props
}) => {
  const { t } = useTranslation();
  const { general } = useCarsApi();
  const [radius, setRadius] = useState(40);
  const [overIcon, setOverIcon] = useState(hover || false);
  const [overDetails, setOverDetails] = useState(false);
  useEffect(() => {
    let newRadius = 40;
    if (data.stations.length < 10) {
      newRadius = 30;
    } else if (data.stations.length < 20) {
      newRadius = 40;
    } else if (data.stations.length >= 20) {
      newRadius = 50;
    }
    setRadius(newRadius);
  }, [data]);

  return (
    <div
      className={styles.carsMarker}
      style={{
        left: `${props.left}px`,
        top: `${props.top}px`,
        transform: `translate(${-radius / 2}px, ${-radius / 2}px)`
      }}
    >
      {(overIcon || overDetails) && (
        <WindowWrapper
          initialLeftOffset={size / 2}
          initialBottomOffset={size}
          anchorX={props.left}
          anchorY={props.top}
          onOverEvent={setOverDetails}
        >
          <StationClusterWindow data={data} setStationFilter={setStationFilter} />
        </WindowWrapper>
      )}

      <div
        className={styles.stationClusterMarker__main}
        style={{
          width: radius,
          height: radius,
          borderRadius: radius,
          outlineWidth: `${radius / 4}px`
        }}
        onPointerDown={() => setStationFilter(data.stations.map((item) => item.id))}
        onMouseEnter={() => setOverIcon(true)}
        onMouseLeave={() => setOverIcon(false)}
      >
        <span className={styles.stationClusterMarker__count}>{data.stations.length}</span>

        <span className={styles.carsMarker__carsQty}>{data.carsQty}</span>

        <Price
          className={styles.stationClusterMarker__priceRange}
          prefix={t('price_from')}
          price={data.minPrice}
          currency={general.currency}
        />
      </div>
    </div>
  );
};

export default StationClusterMarker;
