import React, { FC, useEffect, useState } from 'react';
import { clone } from 'lodash';
import useOffersContext from 'providers/offers/use-offers-context';
import CheckboxButton from 'components/forms/checkbox-button';
import { sameArrays } from 'src/utils/array';
import { ArrayFilter } from 'src/filters/types';
import useTranslation from 'providers/translations/use-translations';
import Price from 'components/common/price';
import useCarsApi from 'providers/cars-api/use-cars-api';
import FilterBox from '../filter-box';
import styles from '../filters.module.scss';

type Props = {
  label?: string;
  disabled?: boolean;
};

export const hasDepositLabel = {
  'has-deposit': 'filter_has_deposit',
  'no-deposit': 'filter_no_deposit'
};

const DepositBooleanFilterBox: FC<Props> = ({ label = undefined, disabled = false }) => {
  const { t } = useTranslation();
  const { filters, updateFilter, pricesStartsFrom } = useOffersContext();
  const { general } = useCarsApi();
  const [selected, setSelected] = useState<Array<string>>([]);
  const DEPOSIT_OPTIONS = ['no-deposit', 'has-deposit'];

  const selectionChange = (hasDeposit: string, checked: boolean) => {
    if (checked) {
      selected.push(hasDeposit);
    } else {
      selected.splice(selected.indexOf(hasDeposit), 1);
    }

    setSelected(clone(selected));
    updateFilter({
      type: 'hasDeposit',
      value: clone(selected)
    });
  };

  const checkForLegalOption = () => {
    let valid = false;
    DEPOSIT_OPTIONS.map((type) => {
      if (
        pricesStartsFrom['hasDeposit'] &&
        pricesStartsFrom['hasDeposit'][type] &&
        pricesStartsFrom['hasDeposit'][type].price
      ) {
        valid = true;
      }
      return null;
    });
    return valid;
  };

  useEffect(() => {
    const filter = filters.find((item) => item.type === 'hasDeposit') as ArrayFilter;
    if (!filter && selected.length !== 0) {
      setSelected([]);
    }

    if (filter && !sameArrays(selected, filter.value)) {
      setSelected(filter.value);
    }
  }, [filters, selected]);

  if (!checkForLegalOption()) return null;

  return (
    <FilterBox title={label}>
      {DEPOSIT_OPTIONS.map((type) => (
        <CheckboxButton
          key={type}
          value={selected.indexOf(type) !== -1}
          onChange={(checked) => selectionChange(type, checked)}
          labelClassName={styles.depositFilter}
          disabled={
            disabled || !pricesStartsFrom['hasDeposit'] || !pricesStartsFrom['hasDeposit'][type]
          }
        >
          <span className={styles.depositFilterPriceLabel}>
            {t(hasDepositLabel[type.toLowerCase()])}
          </span>
          {selected.indexOf(type) < 0 &&
            !disabled &&
            pricesStartsFrom['hasDeposit'] &&
            pricesStartsFrom['hasDeposit'][type] &&
            pricesStartsFrom['hasDeposit'][type].price && (
              <span className={styles.price}>
                {t('price_from')}
                <Price
                  round
                  className={styles.offerPrice}
                  price={pricesStartsFrom['hasDeposit'][type].price}
                  currency={general.currency}
                />
              </span>
            )}
        </CheckboxButton>
      ))}
    </FilterBox>
  );
};

export default DepositBooleanFilterBox;
