import React, {
  useState,
  ReactNode,
  useEffect,
  forwardRef,
  useImperativeHandle,
  CSSProperties
} from 'react';
import classNames from 'classnames';
import useTranslation from 'providers/translations/use-translations';

import CarIcon from 'components/common/car-icon';
import { Validator, ValidatorResponse } from 'src/validators/types';
import { validateValue } from 'src/validators';
import styles from './forms.module.scss';

interface ComponentProps {
  value?: boolean;
  ref?: any;
  className?: string;
  labelClassName?: string;
  style?: CSSProperties;
  label?: string;
  name?: string;
  children?: ReactNode;
  validate?: Array<Validator>;
  disabled?: boolean;
  onChange?: (value: boolean) => void;
}

const CheckboxButton: React.FC<ComponentProps> = forwardRef(
  function CheckboxButtonComponent(props, ref) {
    const { t } = useTranslation('validation');
    const [value, setValue] = useState(props.value);
    const [hover, setHover] = useState(false);
    const [errors, setErrors] = useState([]);

    const runValidation = (val?: boolean): ValidatorResponse => {
      if (props.validate === undefined) return { isValid: true };
      const { isValid, messages } = validateValue(val !== undefined ? val : value, props.validate);
      setErrors(messages);
      return { isValid, messages, name: props.name };
    };

    /* ------------------------------------------------------------------------ EXPOSED METHODS --- */

    useImperativeHandle(ref, () => ({
      validate(): ValidatorResponse {
        return runValidation();
      },
      setState(state: boolean) {
        setValue(state);
      }
    }));

    /* -------------------------------------------------------------------------- HANDLE EVENTS --- */

    const handleChange = () => {
      setValue(!value);
      props.onChange(!value);
      runValidation(!value);
    };

    useEffect(() => {
      if (props.value !== value) {
        setValue(props.value);
      }
    }, [props.value]);

    /* ------------------------------------------------------------------------ RENDER TEMPLATE --- */

    return (
      <button
        type="button"
        disabled={props.disabled}
        className={classNames(
          'input',
          styles.checkboxButton__container,
          props.className,
          'checkboxButton',
          {
            [styles['button--disabled_dark']]: props.disabled,
            [styles['checkboxButton__container--active']]: value,
            'checkboxButton__container--active': value,
            [styles['checkboxButton__container--hover']]: hover,
            'checkboxButton__container--hover': hover
          }
        )}
        onClick={handleChange}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
      >
        <input
          type="checkbox"
          onChange={() => null}
          name={props.label}
          className={styles.checkboxButton}
          checked={value}
        />

        {(props.label !== undefined || props.children) && (
          <div className={classNames(styles.checkboxButton__label, props.labelClassName)}>
            {props.label !== undefined && props.label}
            {props.children ? props.children : null}
          </div>
        )}

        {value && <CarIcon icon="tick" />}

        {errors.length > 0 && hover && (
          <ul className={classNames(styles.fieldErrors, 'fieldErrors')}>
            {errors.map((message, i) => (
              <li key={i}>{t(message.key, message.data)}</li>
            ))}
          </ul>
        )}
      </button>
    );
  }
);

CheckboxButton.defaultProps = {
  value: false,
  onChange: () => null,
  disabled: false
};

export default CheckboxButton;
