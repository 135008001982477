import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC } from 'react';
import { ArrayFilter } from 'src/filters/types';
import { CarEquip } from 'types/car-equip.type';
import UsedFilterWrapper from '../used-filter-wrapper';

type Props = {
  filter: ArrayFilter<CarEquip>;
  labels?: any;
};

const EquipUsedFilter: FC<Props> = ({ filter, labels = undefined }) => {
  const { updateFilter } = useOffersContext();
  if (filter.value.length === 0) return null;

  const remove = (equip: CarEquip) => {
    updateFilter({
      type: 'equip',
      value: filter.value.filter((item) => item !== equip)
    });
  };

  return (
    <>
      {filter.value.map((equip) => (
        <UsedFilterWrapper key={equip} onClear={() => remove(equip)}>
          {labels[equip]}
        </UsedFilterWrapper>
      ))}
    </>
  );
};

export default EquipUsedFilter;
