import React, { FC, memo } from 'react';
import { useRouter } from 'next/router';
import { Moment } from 'moment';
import classNames from 'classnames';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import InputLabel from 'components/forms/input-label';

import CarIcon from 'components/common/car-icon';
import styles from './datepicker.module.scss';
import formStyles from '../forms/forms.module.scss';

type Props = {
  date: Moment;
  focused: boolean;
  icon: string;
  onClick?: () => void;
  style?: any;
  label?: string;
};

const DatepickerInput: FC<Props> = ({
  date,
  focused,
  icon,
  onClick = () => null,
  style = {},
  label = ''
}) => {
  const { locale } = useRouter();
  const { isMobile } = useScreenDetect();

  return (
    <div
      className={classNames(styles.inputContainer, formStyles.iconField__container, {
        [formStyles['iconField--focused']]: focused
      })}
      style={{
        ...style
      }}
    >
      {label && isMobile && <InputLabel label={label} className={formStyles.insideLabel} />}
      <button
        type="button"
        className={classNames(styles.input, formStyles.iconField__inputEnlarged)}
        onClick={onClick}
      >
        <CarIcon icon={icon} size="28px" className={formStyles.iconField__iconEnlarged} />
        {date.locale(locale).format('DD MMM YYYY')}
        <span className={styles.weekdayLabel}>
          {', '}
          {date.locale(locale).format('ddd')}
        </span>
      </button>
    </div>
  );
};

export default memo(DatepickerInput);
