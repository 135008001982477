import React, { FC, useState } from 'react';
import useTranslation from 'providers/translations/use-translations';

import useCarsApi from 'providers/cars-api/use-cars-api';
import Price from 'components/common/price';
import classNames from 'classnames';
import styles from '../stations-map.module.scss';
import StationWindow from './station-window';
import { PigeonProps, StationData } from '../types';
import WindowWrapper from '../window-wrapper';
import { getStationMarkerIconPath } from '../tools';

interface MarkerProps extends PigeonProps {
  data: StationData;
  size?: number;
  highlight?: boolean;
  showPopupWindow?: boolean;
  showCarsCounter?: boolean;
  showPriceRange?: boolean;
  setStationFilter?: (ids: Array<string>) => void;
}

const StationMarker: FC<MarkerProps> = ({
  data,
  size = 36,
  highlight = false,
  showPopupWindow = true,
  showCarsCounter = true,
  showPriceRange = true,
  setStationFilter = () => null,
  ...props
}) => {
  const iconAspectRatio = 26 / 38;
  const { t } = useTranslation();
  const { general } = useCarsApi();
  const [overIcon, setOverIcon] = useState(false);
  const [overDetails, setOverDetails] = useState(false);

  return (
    <div
      className={classNames(styles.carsMarker, styles.stationMarker)}
      style={{
        left: `${props.left}px`,
        top: `${props.top}px`,
        transform: `translate(${-size / 2}px, ${-size / iconAspectRatio}px)`
      }}
    >
      {showPopupWindow && (overIcon || overDetails) && (
        <WindowWrapper
          initialLeftOffset={size / 2}
          initialBottomOffset={size / iconAspectRatio}
          anchorX={props.left}
          anchorY={props.top}
          onOverEvent={setOverDetails}
        >
          <StationWindow station={data} setStationFilter={setStationFilter} />
        </WindowWrapper>
      )}

      <div
        onPointerDown={() => setStationFilter([data.id])}
        onMouseEnter={() => {
          setOverIcon(true);
        }}
        onMouseLeave={() => {
          setOverIcon(false);
        }}
      >
        <svg
          width={size}
          height={size / iconAspectRatio}
          viewBox="0 0 41.1 56.7"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            className={classNames(styles.stationMarker__icon__bg, {
              [styles['stationMarker__icon__bg--highlight']]: highlight
            })}
            d="M20.1,56.7c-0.5-0.4-1-0.7-1.4-1.2C13.9,50,9.6,44.2,6,37.9c-2.1-3.7-4-7.5-5.1-11.6c-1.7-6.1-0.9-12,2.6-17.2
            c3.4-5,8.2-8,14.2-8.9c5.9-0.8,11.2,0.7,15.8,4.4c4.1,3.3,6.5,7.6,7.3,12.8c0.6,4-0.1,7.9-1.5,11.7c-1.9,5-4.6,9.7-7.5,14.1
            c-2.9,4.3-6,8.4-9.4,12.3C22,56,21.5,56.3,21,56.7C20.7,56.7,20.4,56.7,20.1,56.7z"
          />
          <path
            className={styles.stationMarker__icon__shape}
            d={getStationMarkerIconPath(data.type)}
          />
        </svg>
        {showCarsCounter && <span className={styles.carsMarker__carsQty}>{data.cars.length}</span>}

        {showPriceRange && (
          <Price
            className={styles.carsMarker__priceRange}
            prefix={t('price_from')}
            price={data.minPrice}
            currency={general.currency}
          />
        )}
      </div>
    </div>
  );
};

export default StationMarker;
