import React, { FC, memo } from 'react';
import useScreenDetect from 'providers/screen-detect/use-screen-detect';
import useTranslation from 'providers/translations/use-translations';
import { DropdownItem } from 'components/forms/types';
import Dropdown from 'components/forms/dropdown';
import Checkbox from 'components/forms/checkbox';

type Props = {
  sameLocation: boolean;
  onChange: (val: boolean) => void;
};

const SameLocationToggle: FC<Props> = ({ sameLocation, onChange }) => {
  const { t } = useTranslation();
  const { isMobile } = useScreenDetect();
  const options: Array<DropdownItem> = [
    {
      value: true,
      label: t('drel_same_return_place')
    },
    {
      value: false,
      label: t('drel_different_return_place')
    }
  ];

  return (
    <>
      {!isMobile && (
        <Dropdown
          value={options.find((item) => item.value === sameLocation)}
          options={options}
          onChange={(item) => onChange(item.value)}
          noEmptyOption
        />
      )}

      {isMobile && (
        <Checkbox
          value={sameLocation}
          onChange={(item) => onChange(item)}
          label={t('common:drel_same_return_place')}
        />
      )}
    </>
  );
};

export default memo(SameLocationToggle);
