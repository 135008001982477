import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC } from 'react';
import { PassengersFilter } from 'src/filters/types';
import UsedFilterWrapper from '../used-filter-wrapper';
import useTranslation from 'providers/translations/use-translations';
import styles from '../filters.module.scss';

type Props = {
  filter: PassengersFilter;
};

const PassengersUsedFilter: FC<Props> = ({ filter }) => {
  const { removeFilter } = useOffersContext();
  const { t } = useTranslation();
  const remove = () => {
    removeFilter('passengers');
  };

  return (
    <UsedFilterWrapper onClear={() => remove()}>
      <span className={styles.usedFilters__label}>Passengers:</span>
      <span className={styles.usedFilters__value}>{`${filter.value} ${t('and_more')}`}</span>
    </UsedFilterWrapper>
  );
};

export default PassengersUsedFilter;
