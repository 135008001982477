import React, { FC, useEffect, useState } from 'react';
import { clone } from 'lodash';
import useOffersContext from 'providers/offers/use-offers-context';
// import Checkbox from 'components/forms/checkbox';
import CarIcon from 'components/common/car-icon';
import CheckboxButton from 'components/forms/checkbox-button';
import { sameArrays } from 'src/utils/array';
import { CarEquip } from 'types/car-equip.type';
import { ArrayFilter } from 'src/filters/types';
import useTranslation from 'providers/translations/use-translations';
import Price from 'components/common/price';
import useCarsApi from 'providers/cars-api/use-cars-api';
import FilterBox from '../filter-box';

import styles from '../filters.module.scss';

type Props = {
  label?: string;
  disabled?: boolean;
};

const EquipFilterBox: FC<Props> = ({ label = undefined, disabled = false }) => {
  const EQUIP_LIST: Array<CarEquip> = [
    'ac',
    'manualTransmission',
    'automaticTransmission',
    'gps',
    'drive4W',
    'electric',
    'unlimitedkm',
    'prepaid',
    'winterTires',
    'additionalDriver'
  ];

  const { t } = useTranslation();

  const typeMap = {
    ac: {
      icon: 'ac',
      label: t('filters_ac')
    },
    manualTransmission: {
      icon: 'manual-gear',
      label: t('filters_gear_m')
    },
    automaticTransmission: {
      icon: 'automatic-gear',
      label: t('filters_gear_a')
    },
    gps: {
      icon: 'nav',
      label: t('a_gps')
    },
    drive4W: {
      icon: 'awd',
      label: t('filters_4_wheel')
    },
    electric: {
      icon: 'power-cord',
      label: t('filters_eh')
    },
    unlimitedkm: {
      icon: 'distance',
      label: t('filters_unlimited_km')
    },
    prepaid: {
      icon: 'coins',
      label: t('prepaid')
    },
    winterTires: {
      icon: 'tyres',
      label: t('a_winter_tires')
    },
    additionalDriver: {
      icon: 'user-plus',
      label: t('additional_driver')
    }
  };

  const { filters, updateFilter, pricesStartsFrom } = useOffersContext();
  const { general } = useCarsApi();
  const [selected, setSelected] = useState<Array<CarEquip>>([]);

  const selectionChange = (equip: CarEquip, checked: boolean) => {
    if (checked) {
      selected.push(equip);
    } else {
      selected.splice(selected.indexOf(equip), 1);
    }
    setSelected(clone(selected));
    updateFilter({
      type: 'equip',
      value: clone(selected)
    });
  };

  useEffect(() => {
    const filter = filters.find((item) => item.type === 'equip') as ArrayFilter<CarEquip>;
    if (!filter && selected.length !== 0) {
      setSelected([]);
    }

    if (filter && !sameArrays(selected, filter.value)) {
      setSelected(filter.value);
    }
  }, [filters, selected]);

  return (
    <FilterBox title={label}>
      {EQUIP_LIST.map((type: CarEquip) => (
        <CheckboxButton
          key={type}
          value={selected.indexOf(type) !== -1}
          onChange={(checked) => selectionChange(type, checked)}
          labelClassName={styles.equipFilter}
          disabled={disabled || !pricesStartsFrom['equip'] || !pricesStartsFrom['equip'][type]}
        >
          <span>
            <CarIcon icon={typeMap[type].icon} size="22px" />
            <span className={styles.equipFilter__label}>{typeMap[type].label}</span>
          </span>
          {selected.indexOf(type) < 0 &&
            !disabled &&
            pricesStartsFrom['equip'] &&
            pricesStartsFrom['equip'][type] &&
            pricesStartsFrom['equip'][type].price && (
              <span className={styles.price}>
                {t('price_from')}
                <Price
                  round
                  className={styles.offerPrice}
                  price={pricesStartsFrom['equip'][type].price}
                  currency={general.currency}
                />
              </span>
            )}
        </CheckboxButton>
      ))}
    </FilterBox>
  );
};

export default EquipFilterBox;
