import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC } from 'react';
import { DoorsFilter } from 'src/filters/types';
import UsedFilterWrapper from '../used-filter-wrapper';

import styles from '../filters.module.scss';

type Props = {
  filter: DoorsFilter;
};

const DoorsUsedFilter: FC<Props> = ({ filter }) => {
  const { removeFilter } = useOffersContext();

  const remove = () => {
    removeFilter('doors');
  };

  return (
    <UsedFilterWrapper onClear={() => remove()}>
      <span className={styles.usedFilters__label}>Doors:</span>
      <span className={styles.usedFilters__value}>{filter.value}</span>
    </UsedFilterWrapper>
  );
};

export default DoorsUsedFilter;
