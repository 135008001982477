import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC } from 'react';
import { ArrayFilter } from 'src/filters/types';
import UsedFilterWrapper from '../used-filter-wrapper';
import styles from '../filters.module.scss';

type Props = {
  filter: ArrayFilter<string>;
  label?: any;
};

const PickupStationUsedFilter: FC<Props> = ({ filter, label = '' }) => {
  const { stations, updateFilter, removeFilter } = useOffersContext();
  if (filter.value.length === 0) return null;

  const removeStation = (id: string) => {
    updateFilter({
      type: 'pickupStation',
      value: filter.value.filter((item) => item !== id)
    });
  };

  const getStationName = (id: string): string => {
    const station = stations.find((item) => item.id === id);
    return station ? station.name : id;
  };
  return filter.value.length > 3 ? (
    <UsedFilterWrapper onClear={() => removeFilter('pickupStation')}>
      <span className={styles.usedFilters__label}>{`${label}:`}</span>
      <span className={styles.usedFilters__value}>
        {`${filter.value
          .slice(0, 2)
          .map((station) => getStationName(station))
          .join(', ')} +${filter.value.length - 2}`}
      </span>
    </UsedFilterWrapper>
  ) : (
    <>
      {filter.value.map((station) => (
        <UsedFilterWrapper key={station} onClear={() => removeStation(station)}>
          <span className={styles.usedFilters__label}>{`${label}:`}</span>
          <span className={styles.usedFilters__value}>{getStationName(station)}</span>
        </UsedFilterWrapper>
      ))}
    </>
  );
};

export default PickupStationUsedFilter;
