import useOffersContext from 'providers/offers/use-offers-context';
import React, { FC } from 'react';
import useTranslation from 'providers/translations/use-translations';
import { ArrayFilter } from 'src/filters/types';
import UsedFilterWrapper from '../used-filter-wrapper';

type Props = {
  filter: ArrayFilter;
};

const PartnerUsedFilter: FC<Props> = ({ filter }) => {
  const { updateFilter } = useOffersContext();
  const { t } = useTranslation();
  if (filter.value.length === 0) return null;

  const remove = (partner: string) => {
    updateFilter({
      type: 'partner',
      value: filter.value.filter((item) => item !== partner)
    });
  };

  return (
    <>
      {filter.value.map((partner) => (
        <UsedFilterWrapper key={partner} onClear={() => remove(partner)}>
          {t(`partner_${partner}`, partner)}
        </UsedFilterWrapper>
      ))}
    </>
  );
};

export default PartnerUsedFilter;
