import React, { FC, ReactNode, useState, Children } from 'react';
import Button from 'components/forms/button';
import useTranslation from 'providers/translations/use-translations';
import CarIcon from 'components/common/car-icon';
import { SlideDown } from 'components/common/animations';
import styles from './filters.module.scss';

const MAX_LIMIT = 999;
interface Props {
  title?: ReactNode;
  subtitle?: ReactNode;
  children?: ReactNode;
  collapsable?: boolean;
  collapsed?: boolean;
  initialLimit?: number;
}

const FilterBox: FC<Props> = ({
  title = undefined,
  subtitle = undefined,
  children = undefined,
  collapsable = false,
  collapsed = false,
  initialLimit = undefined
}) => {
  const [expanded, setExpanded] = useState(!collapsed);
  const [limit, setLimit] = useState(initialLimit || 0);
  const { t } = useTranslation();

  return (
    <div className={styles.filterBox}>
      <div className={styles.filterBox__heading}>
        <div className={styles.filterBox__heading__upper}>
          {title && <span className={styles.filterBox__title}>{title}</span>}
          {collapsable && (
            <Button
              variant="text"
              icon="chevron-down"
              style={{
                transform: expanded ? 'rotateX(180deg)' : undefined
              }}
              onClick={() => setExpanded(!expanded)}
            />
          )}
        </div>
        {subtitle && <p className={styles.filterBox__subtitle}>{subtitle}</p>}
      </div>

      <SlideDown open={expanded}>
        <div className={styles.filterBox__content}>
          {(!initialLimit || Children.count(children) <= initialLimit) && children}

          {initialLimit && Children.count(children) > initialLimit && (
            <>
              {Children.toArray(children)
                .slice(0, limit)
                .map((item) => item)}

              <Button
                variant="secondary"
                className={styles.filterBox__moreBtn}
                onClick={() => setLimit(MAX_LIMIT === limit ? initialLimit : MAX_LIMIT)}
                noUnderline
              >
                <CarIcon
                  icon="chevron-down"
                  size="13px"
                  style={{
                    transform: limit === MAX_LIMIT ? 'rotateX(180deg)' : undefined
                  }}
                />
                <span>{limit !== MAX_LIMIT ? t('more') : t('less')}</span>
              </Button>
            </>
          )}
        </div>
      </SlideDown>
    </div>
  );
};

export default FilterBox;
